export default [
  {
    index: 0,
    key: "political_level_1",
    labelKey: "political_level_1",
    color: "#b15928",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-peru:political_level_1"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IBC' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/peru/collection_1/download/layers/nivel-politico-1.zip'
  },
  {
    index: 1,
    key: "political_level_2",
    labelKey: "political_level_2",
    color: "#ffff99",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-peru:political_level_2"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'INEI' },
      { labelKey: 'year', label: 'Ano', value: '2017' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/peru/collection_1/download/layers/nivel-politico-2.zip'
  },
  {
    index: 2,
    key: "political_level_3",
    labelKey: "political_level_3",
    color: "#6a3d9a",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-peru:political_level_3"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'INEI' },
      { labelKey: 'year', label: 'Ano', value: '2017' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/peru/collection_1/download/layers/nivel-politico-3.zip'
  },
  {
    index: 3,
    key: "political_level_4",
    labelKey: "political_level_4",
    color: "#cab2d6",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-peru:political_level_4"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'INEI' },
      { labelKey: 'year', label: 'Ano', value: '2017' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/peru/collection_1/download/layers/nivel-politico-4.zip'
  },
  {
    index: 4,
    key: "biome",
    labelKey: "biome",
    color: "#ff7f00",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-peru:biome"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IBC' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/peru/collection_1/download/layers/bioma-pais.zip'
  },
  {
    index: 5,
    key: "basin",
    labelKey: "basin",
    color: "#fdbf6f",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-peru:basin"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'ANA' },
      { labelKey: 'year', label: 'Ano', value: '2021' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/peru/collection_1/download/layers/unidad-hidrografica.zip'
  },
  {
    index: 6,
    key: "titled_native_community",
    labelKey: "titled_native_community",
    color: "#1b7837",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-peru:titled_native_community"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'SICNA/IBC' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/peru/collection_1/download/layers/comunidad-nativa-titulada.zip'
  },
  {
    index: 7,
    key: "expansion_or_titling_application",
    labelKey: "expansion_or_titling_application",
    color: "#e31a1c",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-peru:expansion_or_titling_application"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'SICNA/IBC' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/peru/collection_1/download/layers/comunidad-nativa-solicitud.zip'
  },
  {
    index: 8,
    key: "land_reserve",
    labelKey: "land_reserve",
    color: "#fb9a99",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-peru:land_reserve"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MINCU' },
      { labelKey: 'year', label: 'Ano', value: '2022' },
    ]
  },
  {
    index: 9,
    key: "indigenous_reserve",
    labelKey: "indigenous_reserve",
    color: "#33a02c",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-peru:indigenous_reserve"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MINCU' },
      { labelKey: 'year', label: 'Ano', value: '2022' },
    ]
  },
  {
    index: 10,
    key: "titled_peasant_community",
    labelKey: "titled_peasant_community",
    color: "#b2df8a",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-peru:titled_peasant_community"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'SICCAM/IBC-CEPES' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/peru/collection_1/download/layers/comunidad-campesina-titulada.zip'
  },
  {
    index: 11,
    key: "recognized_peasant_community",
    labelKey: "recognized_peasant_community",
    color: "#1f78b4",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-peru:recognized_peasant_community"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'SICCAM/IBC-CEPES' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/peru/collection_1/download/layers/comunidad-campesina-reconocida.zip'
  },
  {
    index: 12,
    key: "natural_protected_areas",
    labelKey: "natural_protected_areas",
    color: "#a6cee3",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-peru:natural_protected_areas"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'SERNANP' },
      { labelKey: 'year', label: 'Ano', value: '2022' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/peru/collection_1/download/layers/area-natural-protegida.zip'
  },
  {
    index: 13,
    key: "regional_conservation_areas",
    labelKey: "regional_conservation_areas",
    color: "#f768a1",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-peru:regional_conservation_areas"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'SERNANP' },
      { labelKey: 'year', label: 'Ano', value: '2022' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/peru/collection_1/download/layers/area-conservacion-regional.zip'
  },
  {
    index: 14,
    key: "private_conservation_areas",
    labelKey: "private_conservation_areas",
    color: "#ae017e",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-peru:private_conservation_areas"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'SERNANP' },
      { labelKey: 'year', label: 'Ano', value: '2022' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/peru/collection_1/download/layers/area-conservacion-privada.zip'
  },
  {
    index: 15,
    key: "fragile_ecosystem",
    labelKey: "fragile_ecosystem",
    color: "#bf812d",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-peru:fragile_ecosystem"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'SERFOR' },
      { labelKey: 'year', label: 'Ano', value: '2019' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/peru/collection_1/download/layers/ecosistema-fragil.zip'
  },
  {
    index: 16,
    key: "ecozone",
    labelKey: "ecozone",
    color: "#f6e8c3",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-peru:ecozone"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'SERFOR' },
      { labelKey: 'year', label: 'Ano', value: '2017' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/peru/collection_1/download/layers/ecozona.zip'
  },
  {
    index: 17,
    key: "ecoregion",
    labelKey: "ecoregion",
    color: "#c7eae5",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-peru:ecoregion"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MINAM' },
      { labelKey: 'year', label: 'Ano', value: '2017' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/peru/collection_1/download/layers/ecorregion.zip'
  },
  {
    index: 18,
    key: "geographical_region",
    labelKey: "geographical_region",
    color: "#01665e",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-peru:geographical_region"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'ANA' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/peru/collection_1/download/layers/region-geografica.zip'
  },
];
