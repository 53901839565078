export default {
  'territorial': [
    'peru_limit',
    'biome',
    'state',
    'city',
    'district',
    'level_1_drainage_basin',
  ],
  'land': [
    'expansion_or_titling_application',
    'titled_native_community',
    'indigenous_reserve',
    'land_reserve',
    'recognized_peasant_community',
    'titled_peasant_community',
    'natural_protected_areas',
    'regional_conservation_areas',
    'private_conservation_areas',
    'fragile_ecosystem',
    'ecozone',
    'ecoregion',
    'geographical_region',
  ]
};